
a{
    text-decoration: none;
}
@font-face{
    font-family: "Montserrat-Regular";
    src: url("../fonts/Montserrat-Regular.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}
@font-face{
    font-family: "Montserrat-Light";
    src: url("../fonts/Montserrat-Light.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}
@font-face{
    font-family: "Montserrat-Bold";
    src: url("../fonts/Montserrat-Bold.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}
@font-face{
    font-family: "Montserrat-Medium";
    src: url("../fonts/Montserrat-Medium.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

body{
    position: relative;
    overflow-x: hidden;
    background: #FAFAFA;
}
.container_width_in{
    max-width: 1500px;
}
.header{
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 0 30px;
}
a.logo{
    display: inline-block;
    position: relative;
    font-family: "Montserrat-Regular";
    font-size: 18px;
    color: #241E3F;
    line-height: 1.3;
    padding: 20px 0 20px 130px;
    transition: all 0.3s ease-in-out;
}
a.logo:hover{
    font-weight: bold;
    color: #8263FF;
}
a.logo span i{
    display: block;
    font-family: "Montserrat-Bold";
    font-style: normal;
    color: #8263FF;
}
a.logo img{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.menu{
    display: inline-block;
    padding: 20px 160px 20px 0;
    margin: 0;
    float: right;
}
.menu li{
    display: inline-block;
    list-style: none;
    padding: 0 45px 0 0;
}
.menu li a{
    font-family: "Montserrat-Regular";
    font-size: 18px;
    line-height: 1.3;
    color: #241E3F;
    transition: all 0.3s ease-in-out;
}
.menu li a:hover{
    color: #8263FF;
}
.lang{
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
    height: 110px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}
.lang .dropdown .btn-success{
    background-color: #F5F5F5;
    border: none;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    font-family: "Montserrat-Regular";
    font-size: 22px;
    color: #9F9F9F;
    text-transform: uppercase;
}
.lang .btn-check:focus+.lang .btn-success, .lang .btn-success:focus{
    box-shadow: none !important;
}
.lang .dropdown .btn-success::after{
    display: none;
}
.lang .dropdown .dropdown-menu{
    min-width: 120px;
    font-family: "Montserrat-Regular";
    font-size: 18px;
    top: 134% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    border-radius: 0;
    border: none;
    padding: 0;
}
.lang .dropdown .dropdown-menu .dropdown-item{
    background-color: #f8f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    transition: all 0.3s ease-in-out;
}
.lang .dropdown .dropdown-menu .dropdown-item:hover{
    background-color: #e9e8e8;
}
.lang .btn-check:active+.btn-success:focus, .lang .btn-check:checked+.lang .btn-success:focus,
.lang .btn-success.active:focus, .lang .btn-success:active:focus, .show>.lang .btn-success.dropdown-toggle:focus{
    box-shadow: none !important;
}

/****************************************/

.title_in{
    display: block;
    font-family: "Montserrat-Bold";
    font-size: 28px;
    line-height: 1.2;
    color: #413960;
    margin-bottom: 25px;
}
.has_pad{
    padding: 0 0 50px 0;
}
.sector{
    position: relative;
    z-index: 1;
    display: block;
    background-color: #fff;
    box-shadow: 0px 1px 47px #0000001F;
    margin-left: 140px;
    padding: 65px 0 65px 0;
    overflow: hidden;
}
.sector .lottie_svg svg{
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    z-index: -1;
    height: auto !important;
}
.bg_white{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 13px #0000001F;
    border-radius: 19px;
    opacity: 1;
    flex: 0 0 32.333333%;
    max-width: 31.333333%;
    padding: 25px 15px 25px 25px;
    margin: 11px !important;
}
.sector_list{
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
}
.sector_list li{
    position: relative;
    display: block;
    list-style: none;
    margin: 15px 0;
    padding-left: 60px;
}
.sector_list li::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%) matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    width: 20px;
    height: 20px;
    background: #8263FF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}
.sector_list li a{
    display: inline;
    font-family: "Montserrat-Regular";
    font-size: 20px;
    color: #241E3F;
    line-height: 1.3;
    transition: all 0.3s ease-in-out;
}
.sector_list li a:hover{
    color: #8263ff;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
}
.sector_box{
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100px;
    font-family: "Montserrat-Light";
    font-size: 28px;
    color: #413960;
    line-height: 1.1;
    padding: 0 0 0 110px;
    margin-bottom: 40px;
}
.circle{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 73px;
    height: 73px;
    background: #EDE9FF 0% 0% no-repeat padding-box;
    border-radius: 50%;
}
.circle img{
    position: absolute;
    right: -8px;
}
.form_search{
    position: relative;
    display: flex;
    justify-content: center;
    margin: 35px 0 45px 0;
}
.form_search div{
    width: 100%;
    position: relative;
}
.form_search .form-control{
    position: relative;
    width: 100%;
    height: 64px;
    background: #FFFFFF40 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 47px #0000001F;
    border-radius: 19px;
    border: none;
    opacity: 1;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    font-family: "Montserrat-Regular";
    font-size: 18px;
    color: #fff;
    padding: 10px 75px 10px 30px;
}
.form_search .form-control::-webkit-input-placeholder{
    color: #fff;
}
.sub_btn{
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
}
.sub_btn svg.svg_b{
    font-size: 28px;
    color: #fff;
    position: relative;
    top: auto;
    height: auto;
}

/****************************************/

.portal_content{
    position: relative;
    overflow: hidden;
    background-color: #413960;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 474px;
    margin-left: 140px;
    padding: 20px 0;
    z-index: 1;
}
.portal_content::before{
    content: '';
    background: url("../img/portal_img.jpg") center center no-repeat;
    background-size: cover;
    width: 1781px;
    height: 474px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.portal_content::after{
    content: '';
    background: url("../img/portal_after.png") no-repeat;
    width: 482px;
    height: 387px;
    position: absolute;
    left: 160px;
    bottom: 0;
}

/****************************************/

.usefull_links_white, .usefull_links{
    display: block;
    margin-left: 140px;
    background-color: #fff;
    box-shadow: 0px 1px 47px #0000001F;
    padding: 65px 0;
}
.usefull_links{
    background-color: transparent;
    box-shadow: none;
}
.usefull_title{
    display: block;
    font-family: "Montserrat-Bold";
    font-size: 28px;
    line-height: 1.3;
    color: #413960;
    text-align: center;
    padding: 15px 0 0 0;
}
.usefull_list{
    margin: 0;
    padding: 0;
}
.usefull_list li{
    width: 20%;
    display: inline-block;
    vertical-align: top;
    list-style: none;
    text-align: center;
    padding: 20px 25px;
}
.usefull_list li a:first-child{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;
}
.usefull_list li a:first-child img{
    transition: all 0.3s ease-in-out;
}
.usefull_list li a:first-child:hover img{
    transform: scale(1.05);
}
.usefull_list li a{
    display: block;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    color: #6D6D6D;
    line-height: 1.3;
    transition: all 0.3s ease-in-out;
}
.usefull_list li a:hover{
    font-weight: bold;
    color: #505050;
}

/****************************************/

.footer{
    background-color: #100E1C;
    border-top-left-radius: 25px;
    margin-left: 140px;
    padding: 20px 0;
    text-align: center;
}
.footer_list{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 20px 0 10px 0;
}
.footer_list li{
    position: relative;
    display: inline-block;
    list-style: none;
    padding: 20px 40px;
}
.footer_list li::after{
    content: '';
    background-color: #2D2A41;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
.footer_list li:last-child:after{
    display: none;
}
.footer_list li a{
    display: inline-block;
    font-family: "Montserrat-Regular";
    font-size: 14px;
    color: #fff;
}
.attention_info{
    display: block;
    font-family: "Montserrat-Regular";
    font-size: 18px;
    color: #fff;
    line-height: 1.3;
    padding: 10px 0 20px 0;
}
.portal_text{
    width: 550px;
    font-family: "montserrat-Light";
    font-size: 20px;
    color: #fff;
    margin-left: 300px;
}
.portal_text span{
    display: block;
    font-family: "Montserrat-Bold";
    font-size: 42px;
    margin-bottom: 15px;
}
.social_network{
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 140px;
    padding: 0;
    z-index: 1;
}
.social_network li{
    list-style: none;
    text-align: center;
    margin: 25px 0;
}
.social_network li a{
    display: inline-block;
    transition: all 0.3s ease-in-out;
}
.social_network li a:hover{
    transform: scale(1.2);
}

/****************************************/

.in_sec{
    flex: 0 0 auto;
    width: 23.5%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 23px;
    margin: 10px;
    padding: 0;
}
.in_sec a{
    display: inline-block;
    border-radius: 23px;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 20px 25px;
    transition: all 0.3s ease-in-out;
}
.in_sec a:hover{
    -webkit-box-shadow: rgb(40 40 40 / 4%) 0 2px 2px, rgb(40 40 40 / 4%) 0 4px 4px, rgb(38 38 38 / 4%) 0 8px 8px, rgb(32 32 32 / 4%) 0 16px 16px, rgb(45 45 45 / 4%) 0 32px 32px, rgb(35 35 35 / 4%) 0 64px 64px;
    box-shadow: rgb(40 40 40 / 4%) 0 2px 2px, rgb(40 40 40 / 4%) 0 4px 4px, rgb(38 38 38 / 4%) 0 8px 8px, rgb(32 32 32 / 4%) 0 16px 16px, rgb(45 45 45 / 4%) 0 32px 32px, rgb(35 35 35 / 4%) 0 64px 64px;
    -webkit-transform: translate(0, -4px);
    -ms-transform: translate(0, -4px);
    transform: translate(0, -4px);
}
.in_sec a span{
    position: relative;
    display: table;
    width: 119px;
    height: 118px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;
    margin: 0 auto 40px auto;
}
.in_sec a span img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.in_sec a span i{
    position: absolute;
    right: -25px;
    bottom: -0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 58px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;
    font-family: "Montserrat-Bold";
    font-size: 22px;
    font-style: normal;
    color: #413960;
}
.in_sec a p{
    font-family: "Montserrat-Bold";
    font-size: 20px;
    color: #413960;
    line-height: 1.3;
    margin-bottom: 0;
}

/****************************************/

.news_b{
    flex: 0 0 auto;
    width: 31.8%;
    margin: 25px 16px;
}
.news_b:nth-child(3n - 2 ){
    margin: 25px 16px 25px 0;
}
.news_b:nth-child(3n + 0 ){
    margin: 25px 0 25px 16px;
}
.news_img{
    display: block;
    width: 100%;
    height: 305px;
    position: relative;
    overflow: hidden;
}
.news_img img{
    object-fit: fill;
    width: 100%;
    height: 100%;
}
.news_t{
    width: 100%;
    height: 370px;
    border-radius: 18px;
    filter: drop-shadow(0 0 6px rgba(0,0,0,0.16));
    background-color: #ffffff;
    padding: 60px 40px 80px 40px;
    margin-top: -20px;
}
.news_date{
    font-family: "Montserrat-Medium";
    font-size: 18px;
    color: #798084;
    line-height: 1.3;
}
.news_title{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    font-family: "montserrat-Bold";
    font-size: 20px;
    line-height: 1.2;
    color: #252725;
    margin: 15px 0;
}
.news_t p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
}
.reed_stat{
    position: absolute;
    right: 40px;
    bottom: 30px;
    font-family: "Montserrat-Medium";
    font-size: 18px;
    line-height: 1.8;
    color: #798084;
}
.reed_stat svg{
    position: relative;
    top: -1px;
    font-size: 22px;
    margin-right: 5px;
}
.pagination_b .pagination{
    justify-content: center;
    margin: 30px 0 0 0;
}
.pagination_b .pagination li a.page-link{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: none;
    border-radius: 50%;
    padding: 10px 18px;
    font-family: "Montserrat-Medium";
    font-size: 18px;
    line-height: 1.3;
    color: #798084;
    width: 46px;
    height: 46px;
}

.pagination_b .pagination li.active a.page-link{
    position: relative;
    background-color: transparent;
    z-index: 1;
} 
.pagination_b .pagination li.active a.page-link{
    color: #94affc;
}
.pagination_b .pagination li.active a.page-link::before{
    content: '';
    z-index: -1;
    color: #94affc;
    filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));
    background-color: #ffffff;
    width: 46px;
    height: 46px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    margin-top: -2px;
}
.in_news p{
    font-family: "Montserrat-Regular";
    font-size: 20px;
    color: #000000;
}
.in_news .news_img{
    height: 550px;
    border-radius: 20px;
    margin: 40px 0;
}
.in_news .news_img img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.news_right_side{
    display: flex;
    align-items: center;
}
.news_right_side .news_t{
    width: 112%;
    margin-left: -70px;
}
.source_title{
    display: inline-block;
    width: 100%;
    font-family: "Montserrat-Bold";
    font-size: 20px;
    color: #000000;
    line-height: 1.3;
    margin-top: 20px;
}
a.org_name{
    position: relative;
    overflow: hidden;
    width: 31.9%;
    height: 520px;
    display: inline-block;
    vertical-align: top;
    align-items: center;
    border-radius: 16px;
    filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));
    background-color: #ffffff;
    font-family: "Montserrat-Regular";
    font-size: 24px;
    color: #413960;
    line-height: 1.2;
    padding: 25px 35px 25px 35px;
    margin: 12px 30px 12px 0;
}
a.org_name::before{
    content: '';
    background: url("../img/org_name_img.png") no-repeat;
    width: 625px;
    height: 626px;
    position: absolute;
    top: 50px;
    left: -200px;
}
a.org_name:last-child{
    margin: 12px 0;;
}
a.org_name img{
    display: table;
    margin: 50px auto 0 auto;
}
a.org_name span{
    position: absolute;
    bottom: 45px;
    display: flex;
    align-items: center;
    min-height: 60px;
    padding-right: 105px;
}
a.org_name svg.org_svg{
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
    font-size: 30px;
    color: #8263ff;
}
a.org_name span{
    display: flex;
}
.about_text p{
    font-family: "Montserrat-Regular";
    font-size: 20px;
    color: #000000;
}
.flex_b{
    display: flex;
    align-items: center;
    min-height: 100%;
}
.flex_b .col-md-5{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}
.simple_info{
    display: flex;
    align-items: center;
    background: url("../img/about_portal.jpg") no-repeat;
    position: relative;
    overflow: hidden;
    width: 92%;
    height: 880px;
    padding: 30px 150px 30px 200px;
    z-index: 1;
    margin: 30px 0;
}
.info_list{
    padding: 0;
}
.info_list li{
    position: relative;
    width: 45%;
    min-height: 120px;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    list-style: none;
    font-family: "Montserrat-Regular";
    font-size: 20px;
    color: #FFFFFF;
    line-height: 1.3;
    padding-left: 110px;
    margin: 10px 0;
}
.info_list li:nth-child(2n - 1){
    margin: 0 50px 0 0;
}
.info_list li img{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.feedback_b{
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../img/feedback.jpg") center center no-repeat;
    width: 92%;
    height: 820px;
    position: relative;
    overflow: hidden;
    font-family: "Montserrat-Regular";
    font-size: 32px;
    line-height: 1.3;
    color: #fff;
    margin-bottom: -70px;
    padding: 30px 50px;
    text-align: center;
}
.feedback_list{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    padding: 0;
    margin-top: 40px;
}
.feedback_list li{
    position: relative;
    display: inline-block;
    list-style: none;
    font-family: "Montserrat-Bold";
    font-size: 30px;
    color: #fff;
    line-height: 1.2;
    padding-left: 110px;
    margin: 15px 70px;
    text-align: left;
}
.feedback_list li img{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.similar_height{
    position: relative;
    z-index: 9999;
    margin-top: -120px;
}
.slider{
    position: relative;
    display: block;
    width: 100%;
    height: 790px;
    /* padding: 0 140px; */
    margin-top: -70px;
    padding-right: 120px;
}
.alice-carousel{
    position: relative;
    overflow: hidden;
    /* width: 100% !important; */
    height: 790px;
}
.discription{
    width: 880px;
    height: 540px;
    position: absolute;
    z-index: 9999;
    background: #00154F30 0% 0% no-repeat padding-box;
    border-radius: 19px;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    color: #000;
    top: 75px;
    left: 50%;
    color: #fff;
    padding: 30px 50px;
    transform: translateX(-50%);
    margin-left: -212px;
}
.discription_title{
    display: block;
    font-family: "Montserrat-Bold";
    font-size: 42px;
}
.discription_text{
    font-family: "Montserrat-Light";
    font-size: 18px;
    color: #FFFFFF;
}
.discription_info{
    padding: 0;
}
.discription_info li{
    position: relative;
    display: inline-block;
    vertical-align: top;
    list-style: none;
    font-family: "Montserrat-Light";
    font-size: 18px;
    line-height: 1.3;
    margin-right: 70px;
    padding: 0 0 0 30px;
}
.discription_info li::before{
    content: '';
    position: absolute;
    top: 25px;
    left: 0;
    border-left: solid 14px rgb(255,255,255);
    border-bottom: solid 14px transparent;
    border-top: solid 14px transparent;

}
.discription_info li span{
    display: block;
    font-family: "Montserrat-Bold";
    font-size: 42px;
    line-height: 1.2;
}
.change_width_2{
    margin-bottom: 100px;
}
.change_width_2 .col-md-2{
    flex: 0 0 auto;
    width: 14.2%;
}
.highcharts-container{
    height: 275px !important;
}
.highcharts-background{
    fill: transparent;
}
.mini_text{
    display: block;
    font-family: "Montserrat-Regular";
    font-size: 18px;
    color: #413960;
    margin-top: -15px;
}
.another_text{
    display: block;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    color: #413960;
}
.another_text a{
    display: inline;
    border-bottom: 1px solid;
    /* text-decoration: underline; */
    color: #413960;
}
.doctors_number{
    padding: 0;
    margin: 25px 0 40px 0;
}
.doctors_number li{
    position: relative;
    background-color: rgb(75, 218, 139);
    border-radius: 40px;
    display: inline-block;
    width: 350px;
    align-items: center;
    list-style: none;
    font-family: "Montserrat-Bold";
    font-size: 18px;
    color: #fff;
    line-height: 1.3;
    margin: 15px 20px 15px 0;
}
.doctors_number li div{
    display: flex;
    align-items: center;
    height: 70px;
    padding: 10px 35px;
}
.doctors_number li span{
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #4bda8b;
    /* background-color: #52b880; */
    box-shadow: 0px 2px 25px rgb(0 83 12 / 10%);
    border-radius: 40px;
    width: 80px;
    height: 80px;
    color: #4bda8b;
}
.doctors_number li i{
    font-style: normal;
    margin-right: 10px;
}
.chart_t{
    display: block;
    font-family: "Monotserrat-Bold";
    font-size: 20px;
    font-weight: 600;
    color: #000;
    text-align: center;
    margin-bottom: -35px;
}
.marg_b{
    margin: 50px 0;
}